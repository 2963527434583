body .ui.secondary.vertical.segment.breadcrumbs {
  border: none;
  background: transparent;

  .icon {
    height: 20px;
  }
}

body.contenttype-lrf,
body.contenttype-plone-site {
  .breadcrumbs {
    display: none;
  }
}

// Breadcrumbs styles
.home-logo {
  width: auto;
  height: 35px;
}
@media only screen and (max-width: $tabletBreakpoint) {
  // On small screens, show only two elements
  .ui.breadcrumb > * {
    display: none !important;
  }

  .ui.breadcrumb > *:nth-last-child(-n + 3) {
    display: inline-block !important;
  }

  .ui.breadcrumb {
    padding-left: 0.5rem;
  }
}
