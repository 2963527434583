#comment-add-id {
  background-color: red !important;
}

.comments .actions {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
