/* aleo-300 - latin */
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 300;
  src: url('../aleo-v11-latin/aleo-v11-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../aleo-v11-latin/aleo-v11-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../aleo-v11-latin/aleo-v11-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../aleo-v11-latin/aleo-v11-latin-300.svg#Aleo') format('svg'); /* Legacy iOS */
}

/* aleo-300italic - latin */
@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 300;
  src: url('../aleo-v11-latin/aleo-v11-latin-300italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../aleo-v11-latin/aleo-v11-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../aleo-v11-latin/aleo-v11-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../aleo-v11-latin/aleo-v11-latin-300italic.woff') format('woff'),
    /* Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../aleo-v11-latin/aleo-v11-latin-300italic.svg#Aleo') format('svg'); /* Legacy iOS */
}

/* aleo-regular - latin */
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 400;
  src: url('../aleo-v11-latin/aleo-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../aleo-v11-latin/aleo-v11-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../aleo-v11-latin/aleo-v11-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../aleo-v11-latin/aleo-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../aleo-v11-latin/aleo-v11-latin-regular.svg#Aleo') format('svg'); /* Legacy iOS */
}

/* aleo-italic - latin */
@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 400;
  src: url('../aleo-v11-latin/aleo-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../aleo-v11-latin/aleo-v11-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../aleo-v11-latin/aleo-v11-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../aleo-v11-latin/aleo-v11-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../aleo-v11-latin/aleo-v11-latin-italic.svg#Aleo') format('svg'); /* Legacy iOS */
}

/* aleo-700 - latin */
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 700;
  src: url('../aleo-v11-latin/aleo-v11-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../aleo-v11-latin/aleo-v11-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../aleo-v11-latin/aleo-v11-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../aleo-v11-latin/aleo-v11-latin-700.svg#Aleo') format('svg'); /* Legacy iOS */
}

/* aleo-700italic - latin */
@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 700;
  src: url('../aleo-v11-latin/aleo-v11-latin-700italic.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('../aleo-v11-latin/aleo-v11-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../aleo-v11-latin/aleo-v11-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../aleo-v11-latin/aleo-v11-latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('../aleo-v11-latin/aleo-v11-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../aleo-v11-latin/aleo-v11-latin-700italic.svg#Aleo') format('svg'); /* Legacy iOS */
}
