/* components/theme/FileUpload/file-upload.less */

.file-upload-prompt-container {
  .media-file-dropzone {
    width: 60%;
    margin: 0 auto;

    .dropzone-placeholder {
      padding: 30px;
      margin: 0;
      background: #fbfbfb;

      &.active,
      &.active.filled {
        border-color: #21ba45;
        background-color: #e6ffea;
        outline: none;
        transition:
          border 0.24s ease-in-out,
          background-color 0.24s ease-in-out;

        .label-media-file-input {
          display: none;
        }
      }

      &.rejected {
        border-color: #ffa6a6;
        background-color: #fff0f0;
      }

      &.filled {
        border-color: #21ba45;
        background-color: #f9fffa;
      }

      .media-file-label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.7em 0;
        font-size: 1em;
        font-weight: 400;
        text-align: center;
      }

      .dropzone-text {
        width: 100%;
        padding: 10px;
        margin: 0;
        font-weight: 300;
      }

      .label-media-file-input {
        display: block;
        padding: 7px 10px;
        border: 2px solid #007eb1;
        border-radius: 10px;
        margin: 0 auto;
        color: #007eb1;
        cursor: pointer;
        opacity: 0.8;
        text-align: center;

        &.active {
          margin-top: 1em;
        }
      }
    }
  }
}

.details-prompt-container {
  .ui.form {
    .grouped.fields .field {
      margin-left: 1em;

      .ui.checkbox input:focus ~ label {
        color: inherit;
      }
    }
  }

  .details-prompt-subtitles {
    .transcription-language-select {
      display: flex !important;

      .ui.grid {
        flex-wrap: wrap-reverse;
      }
    }
  }

  .details-prompt-range {
    .range-preview {
      margin-top: 1em;
      margin-left: 1em;

      .range-segment {
        height: 140px;
        margin-top: 0;
        text-align: center;

        .range-slider-container {
          width: 1000px;
          height: 56px;
          margin: 0 auto;
          background-repeat: no-repeat;
          background-size: contain;

          .thumb {
            top: 26px;
            appearance: none;
          }

          .thumb::-webkit-slider-thumb {
            width: 3px;
            height: 56px;
            border-radius: 0;
            -webkit-appearance: none;
            background-color: yellow;
          }

          .thumb::-moz-range-thumb {
            width: 3px;
            height: 56px;
            border-radius: 0;
            background-color: yellow;
          }

          .range-slider {
            height: 56px;

            .range-slider-track {
              height: 56px;
              opacity: 0;
            }

            .range-slider-range {
              height: 56px;
              opacity: 0.6;
            }

            .range-slider-left-value {
              margin-top: 60px;
            }

            .range-slider-right-value {
              margin-top: 60px;
            }
          }
        }
      }
    }
  }

  .video-js {
    .vjs-fullscreen-control {
      display: none;
    }

    .vjs-picture-in-picture-control {
      display: none;
    }

    .vjs-quality-selector {
      display: none;
    }
  }
}

.upload-steps {
  display: flex;
  flex-direction: row;

  div.upload-steps-icon-main {
    display: flex;
    align-items: center;

    &.current {
      transform: scale(1.5);
    }

    &.future {
      svg {
        path {
          fill: #b0b0b0;
        }
      }
    }
  }

  div.upload-steps-icon-sub {
    display: block;
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 20px auto;
    background-color: #1c1b1f;

    &.future {
      background-color: #b0b0b0;
    }
  }
}

.upload-video-button-container {
  max-width: $default-container-width;
  margin-right: auto;
  margin-left: auto;
}


@mixin adjustMarginsToContainer($width) {
  @container (max-width: #{$width + 2 * $horizontal-space-small-screens}) {
    margin-right: $horizontal-space-small-screens;
    margin-left: $horizontal-space-small-screens;
  }
}

#page-document .blocks-group-wrapper {
  .upload-video-button-container {
    @include adjustMarginsToContainer($default-container-width);
    max-width: var(--default-container-width);
  }
}
