.view-channels-block {
  display: flex;
  flex-direction: column;
  margin: 60px 0px;

  h2 {
    font-family: Aleo;
    font-size: 50px;
    font-weight: 700;
  }

  .Channel {
    padding: 1rem;
    border-radius: 1rem;
    margin-right: 1rem;
    aspect-ratio: 126 / 187;
    background-color: #f8f8db;

    p {
      font-family: Aleo;
      font-weight: bold;
    }
  }

  .Channels-container {
    display: flex;
    width: 100%;
  }
}
