.downloadsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 21px;
}

.shareheading {
  margin-bottom: 1rem;
}

.button {
  display: flex;
  font-family: lato;
  font-size: 20px;
  border: 1px solid #000;
  border-radius: 50px;
  background-color: transparent;
  padding: 7px 18px;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #E7E7E7;
  }
}

.icon {
  height: 25px;
  width: 25px;
  display: inline-block;
  margin-left: 8px;
}

/* Popup-tyylit */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popupContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  max-width: 800px;
  width: 100%;
  position: relative;
  z-index: 101;
}

.closeButton {
  margin-top: 20px;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url("./close.svg");
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
  width: 50px;
  height: 50px;

}

.shareround {
  border-radius: 50%;
  width: 68px;
  height: 68px;
  background-color: #000;
}

.sharerow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  padding: 20px;
}

.urlField {
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  padding: 15px;
  width: 100%;
}

.copyButton {
  border-radius: 40px;
  background: #E7E7E7;
  color: #000;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    background-color: transparent;
    border: 2px solid #E7E7E7;
  }
}

.sharerow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
}

.embed {
  background-color: #BEBEBE;
  background-image: url("./Embed.svg");
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
}

.moodle {
  background-color: #F98012;
  background-image: url("./Moodle-logo.svg");
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
}

.email {
  background-color: #494949;
  background-image: url("./mail.svg");
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
}

.whatsapp {
  background-color: #17C21D;
  background-image: url("./whatsapp1.svg");
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
}

.facebook {
  background-color: #3B5998;
  background-image: url("./facebook-f1.svg");
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
}

.twitter {
  background-color: black;
  background-image: url("./Twitter.svg");
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
}

.embedField {
  width: 100%;
  padding: 1rem;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.textarea {
  width: 100%;
  min-height: 160px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  font-size: 14px;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }

  10%,
  90% {
    opacity: 0.9;
    transform: translateX(-50%) translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
}

.embedfieldcopy {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.checkboxcontainer {
  display: flex;
  width: 100%;
  padding-left: 25px;
  padding-bottom: 1rem;
  padding-top: 5px;
}

.timelinkcheckbox {
  margin-right: 5px;
}

.timeinput {
  margin-left: 10px;
  width: 65px;
  border: none;
  border-bottom: 1px solid #000;
  padding-bottom: 3px;
}