.Toastify__toast-container {
  @media only screen and (min-width: $mobileBreakpoint) {
    width: 480px !important;
    margin-left: -240px !important;
  }
}

.Toastify__toast {
  overflow: initial;
  align-items: initial !important;
  padding: 8px !important;
  border-radius: 8px;

  &--default {
    background: #e0e1e2;
    color: #002957;
  }

  .toast-dismiss-action {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.Toastify__toast-body {
  font-family: Lato;
  font-size: 15px;

  .toast-grid-container {
    width: 100%;
    margin: 0;

    .ui.grid {
      margin: 0;
    }

    .row {
      padding: 0;
    }

    .toast-icon-row {
      position: absolute;

      .column {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 1em;
      }

      svg.icon {
        position: relative;
        top: -28px;
        border-radius: 50% 50%;
        filter: drop-shadow(0px -2px 1px #00000013);

        &.error {
          background: #f5c1c1;
        }

        &.info {
          background: #aee2f2;
        }

        &.success {
          background: #c9eab1;
        }
      }
    }

    .toast-button {
      height: 100%;
      padding: 7px 15px;
      border: 1px solid #002957;
      margin: 0px;
      background: none;
      color: #002957;

      .toast-button-content {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;

        svg.icon {
          margin-right: 2px;
        }
      }
    }
  }
}
