.Logo-link {
  display: flex;
  color: black;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  text-decoration: none;

  &:hover {
    color: black;
  }
}

.Site-logo {
  width: 65%;
}

.Site-logo-icon {
  display: none;
  width: 85%;
}

@media screen and (max-width: $largeMonitorBreakpointxl) {
  .Site-logo {
    display: none;
  }

  .Site-logo-icon {
    display: block;
  }
}

.header .logo-nav-wrapper .logo {
  flex: 0 0 auto !important;
}
