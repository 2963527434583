#bar-loader-column {
  span:first-child {
    margin: 0 auto;
  }
}

a {
  text-underline-position: under;
}

#toolbar a#toolbar-add-media_page {
  display: none;
}

.ui.secondary.pointing.menu .item.active {
  border-color: #f1563f;
}

.ui.form p.help {
  color: #6f7679;
  font-weight: 400;
}

.ui.secondary.segment {
  background: #f0f6f9;
}

.documentFirstHeading::before {
  border-color: #f1563f;
}

/* Downloads block adjustments */
.downloads-container {
  h3 {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
}

/* Toolbar adjustments */
body {
  // For regular users
  &:not(.user-role-manager):not(.user-role-site-administrator) {
    // Hide add-button for transcodings
    &.contenttype-media_page li a#toolbar-add-transcoding {
      display: none;
    }

    // Hide more-button.
    #toolbar .toolbar-actions .more {
      display: none;
    }
  }
}

// Pagination styles
.listing .pagination-wrapper {
  text-align: unset !important;

  .ui.pagination.menu {
    .item {
      min-width: 2em;
      padding: 0.5em;
    }

    .active.item {
      padding-top: 0.5em;
      font-weight: 600;
    }
  }
}

// Specify what container widths to use.
.blocks-group-wrapper > div {
  max-width: var(--default-container-width) !important;
}

.blocks-group-wrapper .video-inner {
  max-width: var(--default-container-width) !important;
}
